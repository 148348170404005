import { MoonIcon, SunIcon } from '@/components/icons/iconoirIcons'
import { type IFooterProps } from '@/types'
import { useTheme } from 'next-themes'

export default function Footer({ showVersion, showThemeSwitch }: IFooterProps): JSX.Element {
  const { theme, setTheme } = useTheme()

  const renderThemeChanger = (): JSX.Element => {
    if (theme === 'dark') {
      return (
        <SunIcon
          className="ml-4 cursor-pointer text-gray-100"
          onClick={() => setTheme('light')}
        />
      )
    } else {
      return (
        <MoonIcon
          className="ml-4 cursor-pointer text-gray-800"
          onClick={() => setTheme('dark')}
        />
      )
    }
  }

  return (
    <footer className="mt-auto h-14 w-full text-center text-[13px] font-extrabold text-custom-black-800">
      {showVersion && (
        <div className="flex items-center justify-center text-custom-black-800 dark:text-custom-black-100">
          2023 © HumanAI {process.env.NEXT_PUBLIC_APP_VERSION}
          {showThemeSwitch && renderThemeChanger()}
        </div>
      )}
      {!showVersion && <div className="flex items-center justify-center">2023 © HumanAI</div>}
    </footer>
  )
}
