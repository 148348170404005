// Components
import Spinner from '@/components/common/Spinner'
import { signIn } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import { toast } from 'react-toastify'

// Layouts
import LayoutLogin from '@/layouts/layout_login'

// Types
import { type NextPageWithLayout } from '@/types/app'

// Hooks
import { useState, type ReactElement } from 'react'

const Login: NextPageWithLayout = () => {
  // TEXT VARS
  const loginButtonText = 'Iniciar sesión'
  const rememberPasswordText = '¿Olvidó su contraseña?'
  const termsAndCondsText = [
    'Identificandose acepta los',
    'Términos del Código de Ética digital',
    ' y las',
    'Condiciones de uso',
  ]

  const [sendingForm, setSendingForm] = useState(false)
  const [userInfo, setUserInfo] = useState({ username: '', password: '' })

  const handleSubmit: any = async (event: any) => {
    event.preventDefault()

    try {
      setSendingForm(true)
      await signIn('credentials', {
        username: userInfo.username,
        password: userInfo.password,
        redirect: true,
        callbackUrl: `${window.location.origin}/`,
      })
    } catch (err) {
      setSendingForm(false)
      console.log('Error due to login, check:', { err })
      toast.error('Se ha producido un error al realizar el login', {
        icon: '🚨',
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      })
    }
  }

  return (
    <div className="flex flex-col items-center justify-center md:flex-row">
      <div>
        <Image
          src="/human-ai-logo.webp"
          alt="HumanAI Logo"
          priority={true}
          width={180}
          height={180}
        />
      </div>
      <div className="flex w-[350px] flex-col items-center justify-center rounded-xl border-[1px] border-custom-black-300 bg-custom-primary-blue-3/[0.1] py-4 shadow-lg">
        <h1 className="mb-4 text-[30px] font-extrabold dark:text-custom-black-100">Login</h1>
        <form
          className="flex flex-col items-center justify-center"
          action="#"
          onSubmit={handleSubmit}
        >
          <input
            className="mb-4 w-[280px] rounded-xl border-2 border-custom-black-300 p-2 text-[12px] focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600"
            value={userInfo.username}
            type="email"
            placeholder="Email"
            onChange={({ target }) => {
              setUserInfo({ ...userInfo, username: target.value })
            }}
          />
          <input
            className="mb-8 w-[280px] rounded-xl border-2 border-custom-black-300 p-2 text-[12px] focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 dark:text-custom-black-800"
            value={userInfo.password}
            type="password"
            placeholder="Password"
            // minLength={8}
            required
            onChange={({ target }) => {
              setUserInfo({ ...userInfo, password: target.value })
            }}
          />
          <button
            className="mb-4 flex w-[280px] cursor-pointer items-center justify-center rounded-xl border-2 border-custom-black-800 bg-custom-primary-blue-5 p-2 text-[18px] font-extrabold text-custom-black-100 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600"
            type="submit"
          >
            {sendingForm ? <Spinner /> : loginButtonText}
          </button>
        </form>
        <div className="flex flex-col items-center justify-center">
          <span className="mb-4 text-[13px] text-custom-primary-blue-4">
            <Link href="/recover-password">{rememberPasswordText}</Link>
          </span>
          <hr className="mb-4 w-48 border-[1px] border-custom-black-300" />
          <span className="w-full break-normal px-8 text-[13px] dark:text-custom-black-100">
            {termsAndCondsText[0]}{' '}
            <a
              target="_blank"
              href="https://humanaitech.com/codigo-etica-digital"
              rel="noopener noreferrer"
              className="text-custom-primary-blue-4 underline underline-offset-4"
            >
              {termsAndCondsText[1]}
            </a>
            {termsAndCondsText[2]}{' '}
            <a
              target="_blank"
              href="https://humanaitech.com/aviso-legal"
              rel="noopener noreferrer"
              className="text-custom-primary-blue-4 underline underline-offset-4"
            >
              {termsAndCondsText[3]}
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

Login.getLayout = function getLayout(page: ReactElement) {
  return <LayoutLogin title="HumanAI - Login Page">{page}</LayoutLogin>
}

export default Login
